<template>
	<div id="gallery" class="row row-fluid row-has-fill row-no-padding">
		<div class="col-sm-12">
			<div class="column-inner">
				<div class="wrapper">
					<div class="spacer-sm"></div>
					<div class="title">
						<h2 class="head-fancy mt-0" style="color: #ffffff;">Our <span>Gallery</span></h2>
					</div>
					<div class="spacer"></div>
					<div class="gallery-quick-inner">
						<carousel :nav="true" :navText="['&#10229;','&#10230;']" :autoplay="false" :loop="true" :dots="false" :responsive="{0:{items:1,nav:false},600:{items:3,nav:true}}">
							<img v-for="img in gallery" v-bind:key="img.id" :src="img.src" alt="img.alt">
						</carousel>
					</div>
					<div class="spacer-xs"></div>
					<div style="text-align: center;">
						<a id="gallery-btn" href="#gallery" class="btn-side" style="">
							View More
							<span>
								<b-icon icon="plus" font-scale="1" style="display: inline-block; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;"></b-icon>
							</span>
						</a>
					</div>
					<div class="spacer"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import carousel from 'vue-owl-carousel'

	import img1 from '../assets/photos/Monte_Kope_1.jpg'
	import img2 from '../assets/photos/Monte_Kope_2.jpg'
	import img3 from '../assets/photos/Monte_Kope_4.jpg'
	import img4 from '../assets/photos/Monte_Kope_5.jpg'
	import img5 from '../assets/photos/Monte_Kope_6.jpg'
	import img6 from '../assets/photos/Monte_Kope_7.jpg'
	import img7 from '../assets/photos/Monte_Kope_8.jpg'
	import img8 from '../assets/photos/Monte_Kope_9.jpg'
	import img9 from '../assets/photos/Monte_Kope_10.jpg'
	import img10 from '../assets/photos/Monte_Kope_11.jpg'
	import img11 from '../assets/photos/Monte_Kope_12.jpg'
	//import img12 from '../assets/photos/Monte_Kope_12.jpg'
	import img13 from '../assets/photos/Monte_Kope_13.jpg'
	import img14 from '../assets/photos/Monte_Kope_14.jpg'
	import img15 from '../assets/photos/Monte_Kope_15.jpg'
	import img16 from '../assets/photos/Monte_Kope_16.jpg'
	import img17 from '../assets/photos/Monte_Kope_17.jpg'
	import img18 from '../assets/photos/Monte_Kope_20.jpg'
	import img19 from '../assets/photos/Monte_Kope_21.jpg'
	import img20 from '../assets/photos/Monte_Kope_22.jpg'
	import img21 from '../assets/photos/Monte_Kope_24.jpg'
	import img22 from '../assets/photos/Monte_Kope_25.jpg'
	import img23 from '../assets/photos/Monte_Kope_26.jpg'
	import img24 from '../assets/photos/Monte_Kope_27.jpg'
	import img25 from '../assets/photos/Monte_Kope_28.jpg'
	import img26 from '../assets/photos/Monte_Kope_29.jpg'
	import img27 from '../assets/photos/Monte_Kope_30.jpg'
	import img28 from '../assets/photos/Monte_Kope_31.jpg'
	//import img29 from '../assets/photos/Monte_Kope_32.jpg'
	import img30 from '../assets/photos/Monte_Kope_33.jpg'
	import img31 from '../assets/photos/Monte_Kope_34.jpg'
	import img32 from '../assets/photos/Monte_Kope_35.jpg'
	import img33 from '../assets/photos/Monte_Kope_36.jpg'
	import img34 from '../assets/photos/Monte_Kope_37.jpg'
	import img35 from '../assets/photos/Monte_Kope_38.jpg'
	import img36 from '../assets/photos/Monte_Kope_39.jpg'
	import img37 from '../assets/photos/Monte_Kope_40.jpg'
	//import img38 from '../assets/photos/Monte_Kope_41.jpg'
	//import img39 from '../assets/photos/Monte_Kope_42.jpg'
	//import img40 from '../assets/photos/Monte_Kope_43.jpg'
	//import img41 from '../assets/photos/Monte_Kope_44.jpg'
	//import img42 from '../assets/photos/Monte_Kope_45.jpg'
	import img43 from '../assets/photos/Monte_Kope_46.jpg'
	import img44 from '../assets/photos/Monte_Kope_47.jpg'
	import img45 from '../assets/photos/Monte_Kope_49.jpg'
	import img46 from '../assets/photos/Monte_Kope_50.jpg'
	import img47 from '../assets/photos/Monte_Kope_51.jpg'
	//import img48 from '../assets/photos/Monte_Kope_52.jpg'
	import img49 from '../assets/photos/Monte_Kope_53.jpg'
	import img50 from '../assets/photos/Monte_Kope_54.jpg'
	import img51 from '../assets/photos/Monte_Kope_55.jpg'
	import img52 from '../assets/photos/Monte_Kope_56.jpg'
	import img53 from '../assets/photos/Monte_Kope_57.jpg'
	import img54 from '../assets/photos/Monte_Kope_58.jpg'
	import img55 from '../assets/photos/Monte_Kope_59.jpg'
	//import img56 from '../assets/photos/Monte_Kope_60.jpg'
	import img57 from '../assets/photos/Monte_Kope_61.jpg'
	import img58 from '../assets/photos/Monte_Kope_62.jpg'
	import img59 from '../assets/photos/Monte_Kope_63.jpg'
	import img60 from '../assets/photos/Monte_Kope_64.jpg'
	import img61 from '../assets/photos/Monte_Kope_65.jpg'
	import img62 from '../assets/photos/Monte_Kope_66.jpg'
	import img63 from '../assets/photos/Monte_Kope_67.jpg'
	import img64 from '../assets/photos/Monte_Kope_68.jpg'
	import img65 from '../assets/photos/Monte_Kope_69.jpg'
	import img66 from '../assets/photos/Monte_Kope_70.jpg'
	import img67 from '../assets/photos/Monte_Kope_71.jpg'	
	//import img68 from '../assets/photos/Monte_Kope_73.jpg' 
	//import img69 from '../assets/photos/Monte_Kope_74.jpg'
	//import img70 from '../assets/photos/Monte_Kope_75.jpg'
	//import img71 from '../assets/photos/Monte_Kope_76.jpg'
	//import img72 from '../assets/photos/Monte_Kope_77.jpg'
	import img73 from '../assets/photos/Monte_Kope_78.jpg'
	import img74 from '../assets/photos/Monte_Kope_79.jpg'
	import img75 from '../assets/photos/Monte_Kope_80.jpg'
	import img76 from '../assets/photos/Monte_Kope_sauna.jpg'
	//import img77 from '../assets/photos/Monte_Kope_sauna_2.jpg'
	import img78 from '../assets/photos/Monte_Kope_81.jpg'
	import img79 from '../assets/photos/Monte_Kope_82.jpg'
	import img80 from '../assets/photos/Monte_Kope_83.jpg'
	import img81 from '../assets/photos/Monte_Kope_84.jpg'
	//import img82 from '../assets/photos/Monte_Kope_85.jpg'
	import img83 from '../assets/photos/Monte_Kope_86.jpg'
	//import img84 from '../assets/photos/Monte_Kope_87.jpg'
	//import img85 from '../assets/photos/Monte_Kope_88.jpg'
	//import img86 from '../assets/photos/Monte_Kope_89.jpg'
	import img87 from '../assets/photos/Monte_Kope_90.jpg'
	//import img88 from '../assets/photos/Monte_Kope_91.jpg'
	import img89 from '../assets/photos/Monte_Kope_92.jpg'
	import img90 from '../assets/photos/Monte_Kope_93.jpg'
	import img91 from '../assets/photos/Monte_Kope_94.jpg'
	import img92 from '../assets/photos/Monte_Kope_95.jpg'
	import img93 from '../assets/photos/Monte_Kope_96.jpg'
	import img94 from '../assets/photos/Monte_Kope_97.jpg'
	import img95 from '../assets/photos/Monte_Kope_98.jpg'
	import img96 from '../assets/photos/Monte_Kope_99.jpg'
	import img97 from '../assets/photos/Monte_Kope_100.jpg'
	import img98 from '../assets/photos/Monte_Kope_101.jpg'
	import img99 from '../assets/photos/Monte_Kope_102.jpg'
	import img100 from '../assets/photos/Monte_Kope_103.jpg'
	import img101 from  '../assets/photos/Monte_Kope_104.jpg'
	import img102 from '../assets/photos/Monte_Kope_105.jpg'
	import img103 from '../assets/photos/Monte_Kope_106.jpg'
	import img104 from '../assets/photos/Monte_Kope_107.jpg'
	import img105 from '../assets/photos/Monte_Kope_108.jpg'
	import img106 from '../assets/photos/Monte_Kope_109.jpg'
	import img107 from '../assets/photos/Monte_Kope_110.jpg'
	import img108 from '../assets/photos/Monte_Kope_111.jpg'
	import img109 from '../assets/photos/Monte_Kope_112.jpg'
	import img110 from '../assets/photos/Monte_Kope_113.jpg'
	import img111 from '../assets/photos/Monte_Kope_114.jpg'
	import img112 from '../assets/photos/Monte_Kope_115.jpg'
	import img113 from '../assets/photos/Monte_Kope_116.jpg'
	import img114 from '../assets/photos/Monte_Kope_117.jpg'
	import img115 from '../assets/photos/Monte_Kope_118.jpg'
	import img116 from '../assets/photos/Monte_Kope_119.jpg'
	import img117 from '../assets/photos/Monte_Kope_120.jpg'
	import img118 from '../assets/photos/Monte_Kope_121.jpg'
	import img119 from '../assets/photos/Monte_Kope_122.jpg'
	import img120 from '../assets/photos/Monte_Kope_123.jpg'
	import img121 from '../assets/photos/Monte_Kope_124.jpg'
	import img122 from '../assets/photos/Monte_Kope_DJI_0237.jpg'
	import img123 from '../assets/photos/Monte_Kope_DJI_0239.jpg'
	import img124 from '../assets/photos/Monte_Kope_DJI_0241.jpg'
	import img125 from '../assets/photos/Monte_Kope_DJI_0243.jpg'
	import img126 from '../assets/photos/Monte_Kope_DJI_0245.jpg'
	import img127 from '../assets/photos/Monte_Kope_DJI_0248.jpg'

	export default {
		name: "gallery",
		data () {
			return {
				gallery: [
					{id: 1, src: img1, alt: 'Villa Monte Kope photo 1'},
					{id: 2, src: img2, alt: 'Villa Monte Kope photo 2'},
					{id: 3, src: img3, alt: 'Villa Monte Kope photo 3'},
					{id: 4, src: img4, alt: 'Villa Monte Kope photo 4'},
					{id: 5, src: img5, alt: 'Villa Monte Kope photo 5'},
					{id: 6, src: img6, alt: 'Villa Monte Kope photo 6'},
					{id: 7, src: img7, alt: 'Villa Monte Kope photo 7'},
					{id: 8, src: img8, alt: 'Villa Monte Kope photo 8'},
					{id: 9, src: img9, alt: 'Villa Monte Kope photo 9'},
					{id: 10, src: img10, alt: 'Villa Monte Kope photo 10'},
					{id: 11, src: img11, alt: 'Villa Monte Kope photo 11'},
					//{id: 12, src: img12, alt: 'Villa Monte Kope photo 12'},
					{id: 13, src: img13, alt: 'Villa Monte Kope photo 13'},
					{id: 14, src: img14, alt: 'Villa Monte Kope photo 14'},
					{id: 15, src: img15, alt: 'Villa Monte Kope photo 15'},
					{id: 16, src: img16, alt: 'Villa Monte Kope photo 16'},
					{id: 17, src: img17, alt: 'Villa Monte Kope photo 17'},
					{id: 18, src: img18, alt: 'Villa Monte Kope photo 18'},
					{id: 19, src: img19, alt: 'Villa Monte Kope photo 19'},
					{id: 20, src: img20, alt: 'Villa Monte Kope photo 20'},
					{id: 21, src: img21, alt: 'Villa Monte Kope photo 21'},
					{id: 22, src: img22, alt: 'Villa Monte Kope photo 22'},
					{id: 23, src: img23, alt: 'Villa Monte Kope photo 23'},
					{id: 24, src: img24, alt: 'Villa Monte Kope photo 24'},
					{id: 25, src: img25, alt: 'Villa Monte Kope photo 25'},
					{id: 26, src: img26, alt: 'Villa Monte Kope photo 26'},
					{id: 27, src: img27, alt: 'Villa Monte Kope photo 27'},
					{id: 28, src: img28, alt: 'Villa Monte Kope photo 28'},
					//{id: 29, src: img29, alt: 'Villa Monte Kope photo 29'},
					{id: 30, src: img30, alt: 'Villa Monte Kope photo 30'},
					{id: 31, src: img31, alt: 'Villa Monte Kope photo 31'},
					{id: 32, src: img32, alt: 'Villa Monte Kope photo 32'},
					{id: 33, src: img33, alt: 'Villa Monte Kope photo 33'},
					{id: 34, src: img34, alt: 'Villa Monte Kope photo 34'},
					{id: 35, src: img35, alt: 'Villa Monte Kope photo 35'},
					{id: 36, src: img36, alt: 'Villa Monte Kope photo 36'},
					{id: 37, src: img37, alt: 'Villa Monte Kope photo 37'},
					//{id: 38, src: img38, alt: 'Villa Monte Kope photo 38'},
					//{id: 39, src: img39, alt: 'Villa Monte Kope photo 39'},
					//{id: 40, src: img40, alt: 'Villa Monte Kope photo 40'},
					//{id: 41, src: img41, alt: 'Villa Monte Kope photo 41'},
					//{id: 42, src: img42, alt: 'Villa Monte Kope photo 42'},
					{id: 43, src: img43, alt: 'Villa Monte Kope photo 43'},
					{id: 44, src: img44, alt: 'Villa Monte Kope photo 44'},
					{id: 45, src: img45, alt: 'Villa Monte Kope photo 45'},
					{id: 46, src: img46, alt: 'Villa Monte Kope photo 46'},
					{id: 47, src: img47, alt: 'Villa Monte Kope photo 47'},
					//{id: 48, src: img48, alt: 'Villa Monte Kope photo 48'},
					{id: 49, src: img49, alt: 'Villa Monte Kope photo 49'},
					{id: 50, src: img50, alt: 'Villa Monte Kope photo 50'},
					{id: 51, src: img51, alt: 'Villa Monte Kope photo 51'},
					{id: 52, src: img52, alt: 'Villa Monte Kope photo 52'},
					{id: 53, src: img53, alt: 'Villa Monte Kope photo 53'},
					{id: 54, src: img54, alt: 'Villa Monte Kope photo 54'},
					{id: 55, src: img55, alt: 'Villa Monte Kope photo 55'},
					//{id: 56, src: img56, alt: 'Villa Monte Kope photo 56'},
					{id: 57, src: img57, alt: 'Villa Monte Kope photo 57'},
					{id: 58, src: img58, alt: 'Villa Monte Kope photo 58'},
					{id: 59, src: img59, alt: 'Villa Monte Kope photo 59'},
					{id: 60, src: img60, alt: 'Villa Monte Kope photo 60'},
					{id: 61, src: img61, alt: 'Villa Monte Kope photo 61'},
					{id: 62, src: img62, alt: 'Villa Monte Kope photo 62'},
					{id: 63, src: img63, alt: 'Villa Monte Kope photo 63'},
					{id: 64, src: img64, alt: 'Villa Monte Kope photo 64'},
					{id: 65, src: img65, alt: 'Villa Monte Kope photo 65'},
					{id: 66, src: img66, alt: 'Villa Monte Kope photo 66'},
					{id: 67, src: img67, alt: 'Villa Monte Kope photo 67'},
					//{id: 68, src: img68, alt: 'Villa Monte Kope photo 68'},
					//{id: 69, src: img69, alt: 'Villa Monte Kope photo 69'},
					//{id: 70, src: img70, alt: 'Villa Monte Kope photo 70'},
					//{id: 71, src: img71, alt: 'Villa Monte Kope photo 71'},
					//{id: 72, src: img72, alt: 'Villa Monte Kope photo 72'},
					{id: 73, src: img73, alt: 'Villa Monte Kope photo 73'},
					{id: 74, src: img74, alt: 'Villa Monte Kope photo 74'},
					{id: 75, src: img75, alt: 'Villa Monte Kope photo 75'},
					{id: 76, src: img76, alt: 'Villa Monte Kope photo 76'},
					//{id: 77, src: img77, alt: 'Villa Monte Kope photo 77'},
					{id: 78, src: img78, alt: 'Villa Monte Kope photo 78'},
					{id: 79, src: img79, alt: 'Villa Monte Kope photo 79'},
					{id: 80, src: img80, alt: 'Villa Monte Kope photo 80'},
					{id: 81, src: img81, alt: 'Villa Monte Kope photo 81'},
					//{id: 82, src: img82, alt: 'Villa Monte Kope photo 82'},
					{id: 83, src: img83, alt: 'Villa Monte Kope photo 83'},
					//{id: 84, src: img84, alt: 'Villa Monte Kope photo 84'},
					//{id: 85, src: img85, alt: 'Villa Monte Kope photo 85'},
					//{id: 86, src: img86, alt: 'Villa Monte Kope photo 86'},
					{id: 87, src: img87, alt: 'Villa Monte Kope photo 87'},
					//{id: 88, src: img88, alt: 'Villa Monte Kope photo 88'},
					{id: 89, src: img89, alt: 'Villa Monte Kope photo 89'},
					{id: 90, src: img90, alt: 'Villa Monte Kope photo 90'},
					{id: 91, src: img91, alt: 'Villa Monte Kope photo 91'},
					{id: 92, src: img92, alt: 'Villa Monte Kope photo 92'},
					{id: 93, src: img93, alt: 'Villa Monte Kope photo 93'},
					{id: 94, src: img94, alt: 'Villa Monte Kope photo 94'},
					{id: 95, src: img95, alt: 'Villa Monte Kope photo 95'},
					{id: 96, src: img96, alt: 'Villa Monte Kope photo 96'},
					{id: 97, src: img97, alt: 'Villa Monte Kope photo 97'},
					{id: 98, src: img98, alt: 'Villa Monte Kope photo 98'},
					{id: 99, src: img99, alt: 'Villa Monte Kope photo 99'},
					{id: 100, src: img100, alt: 'Villa Monte Kope photo 100'},
					{id: 101, src: img101, alt: 'Villa Monte Kope photo 101'},
					{id: 102, src: img102, alt: 'Villa Monte Kope photo 102'},
					{id: 103, src: img103, alt: 'Villa Monte Kope photo 103'},
					{id: 104, src: img104, alt: 'Villa Monte Kope photo 104'},
					{id: 105, src: img105, alt: 'Villa Monte Kope photo 105'},
					{id: 106, src: img106, alt: 'Villa Monte Kope photo 106'},
					{id: 107, src: img107, alt: 'Villa Monte Kope photo 107'},
					{id: 108, src: img108, alt: 'Villa Monte Kope photo 108'},
					{id: 109, src: img109, alt: 'Villa Monte Kope photo 109'},
					{id: 110, src: img110, alt: 'Villa Monte Kope photo 110'},
					{id: 111, src: img111, alt: 'Villa Monte Kope photo 111'},
					{id: 112, src: img112, alt: 'Villa Monte Kope photo 112'},
					{id: 113, src: img113, alt: 'Villa Monte Kope photo 113'},
					{id: 114, src: img114, alt: 'Villa Monte Kope photo 114'},
					{id: 115, src: img115, alt: 'Villa Monte Kope photo 115'},
					{id: 116, src: img116, alt: 'Villa Monte Kope photo 116'},
					{id: 117, src: img117, alt: 'Villa Monte Kope photo 117'},
					{id: 118, src: img118, alt: 'Villa Monte Kope photo 118'},
					{id: 119, src: img119, alt: 'Villa Monte Kope photo 119'},
					{id: 120, src: img120, alt: 'Villa Monte Kope photo 120'},
					{id: 121, src: img121, alt: 'Villa Monte Kope photo 121'},
					{id: 122, src: img122, alt: 'Villa Monte Kope photo 122'},
					{id: 123, src: img123, alt: 'Villa Monte Kope photo 123'},
					{id: 124, src: img124, alt: 'Villa Monte Kope photo 124'},
					{id: 125, src: img125, alt: 'Villa Monte Kope photo 125'},
					{id: 126, src: img126, alt: 'Villa Monte Kope photo 126'},
					{id: 127, src: img127, alt: 'Villa Monte Kope photo 127'},
				]
			}
		},
		components: {
			carousel
		}
	}
</script>

<style>
	#gallery {
		padding-left: 70px;
		background-color: #030303;
	}
	.gallery-quick-inner {
		display: inline-block;
		width: 100%;
		height: 475px;
	}
	#gallery-btn:hover {
		color: #ffffff;
	}
	.owl-theme .owl-nav {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
        line-height: 18px;
	}
	.owl-nav .owl-prev {
		width: 50px;
		height: 50px;
		float: left;
		margin-left: 50px !important;
		padding: 15px 7px !important;
		background-color: #3f85ce!important;
		color: #ffffff !important;
	}
	.owl-nav .owl-next {
		width: 50px;
		height: 50px;
		padding: 15px 7px !important;
		float: right;
		margin-right: 50px !important;
		background-color: #3f85ce !important;
		color: #ffffff !important;
	}
	@media (max-width: 576px) {
		#gallery {
			padding-left: 10px;
		}
	}

</style>
