<template>
	<div id="amenities" class="row row-fluid high-item-detail">
		<div class="container">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div class="spacer"></div>
						<div class="title">
							<h2 class="head-fancy mt-0">Features and <span>Amenities</span></h2>
						</div>
						<div class="spacer"></div>
						<section class="item-detail-main" style="padding-bottom: 0;">
							<div class="detail-tab">
								<ul class="nav nav-tabs">
									<li @click="toggleTabs = !toggleTabs" :class="{ active: toggleTabs}" style="display: inline-block;">
										<a>Exterior details</a>
									</li>
									<li @click="toggleTabs = !toggleTabs" :class="{ active: !toggleTabs}" style="display: inline-block;">
										<a>Interior details</a>
									</li>
								</ul>
								<div class="tab-content">
									<div v-if="toggleTabs" class="tab-panel">
										<div class="tab-info">
											<h5>Exterior Details</h5>
											<span class="divider-line"></span>
											<div class="icon-group">
												<div class="row">
													<div class="col-sm-6">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Energy self-sustainable</h6>
																<span><i>100% renewable energy with solar panels</i></span>
															</div>
														</div>
													</div>
													<div class="col-sm-6">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Parking capacity: 4</h6>
																<span><i>Front Side</i></span>
															</div>
														</div>
													</div>
													<div class="col-sm-6">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Swimming pool: 1</h6>
																<span><i>heated 6x8 sq.m. with Stairs</i></span>
															</div>
														</div>
													</div>
													<div class="col-sm-6">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Garden:</h6>
																<span><i>Olive Trees, Lawn, Outside Shower</i></span>
															</div>
														</div>
													</div>
													<div class="col-sm-6">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Whirlpool: 1</h6>
																<span><i>106 jets, 40 lights, speakers with subwoofer</i></span>
															</div>
														</div>
													</div>
													<div class="col-sm-6">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Entertainment: 3</h6>
																<span><i>Kids playground, table tennis, exercise polygon</i></span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-else class="tab-panel">
										<div class="tab-info">
											<h5>Interior Details</h5>
											<span class="divider-line"></span>
											<div class="icon-group">
												<div class="row">
													<div class="col-md-4 col-sm-4 col-xs-12">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Bathrooms: 5</h6>
																<span><i>4 en-suite + 1</i></span>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-4 col-xs-12">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Bedrooms: 4</h6>
																<span><i>With Double Beds</i></span>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-4 col-xs-12">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Air conditioning:</h6>
																<span><i>Every Room</i></span>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-4 col-xs-12">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Internet:</h6>
																<span><i>Wi-Fi all over the villa</i></span>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-4 col-xs-12">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Wellness room:</h6>
																<span><i>Sauna and relaxation lounge chairs</i></span>
															</div>
														</div>
													</div>
													<div class="col-md-4 col-sm-4 col-xs-12">
														<div class="icon-wrap">
															<div class="icon-left">
																<div class="icon-book">
																	<b-icon icon="chat-quote"></b-icon>
																</div>
															</div>
															<div class="icon-right">
																<h6>Play equipment:</h6>
																<span><i>Billiard, video-games console</i></span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<div class="spacer"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "amenities",
		data () {
			return {
				toggleTabs: true
			}
		}
	}
</script>

<style>
	.tab-content {
		display: inline-block;
		width: 100%;
		margin: 30px 0 0;
	}
	.tab-info {
		display: inline-block;
		width: 100%;
		padding: 50px;
		background: #fff;
		border: 10px solid #ececec;
		box-shadow: none;
		text-align: left;
	}
	.detail-tab {
		float: left;
		width: 100%;
		margin-top: 0;
		position: relative;
		z-index: 1;
	}
	.detail-tab ul {
		text-align: center;
		border: none;
	}
	.detail-tab ul {
		display: block;
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.detail-tab .nav-tabs li a {
		padding: 10px 25px 12px;
		background: 0 0;
		color: #303030;
		font-weight: 700;
		font-size: 16px;
		position: relative;
		display: block;
		border: 1px solid #949699;
		border-radius: 0;
		cursor: pointer;
	}
	.detail-tab .nav-tabs li.active a {
		color: #ffffff;
		background: #3f85ce;
		border-color: #3f85ce;
		cursor: default;
	}
	.detail-tab .nav-tabs>li.active>a:before {
		content: "";
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px dashed #3f85ce;
		display: inline-block;
		height: 0;
		vertical-align: middle;
		width: 0;
		position: absolute;
		bottom: -8px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	.nav-tabs li a {
		margin-right: 2px;
		line-height: 1.42857143;
		border: 1px solid transparent;
		border-radius: 4px 4px 0 0;
	}
	.nav li a {
		margin-right: 2px;
		line-height: 1.42857143;
	}

</style>
